<template>
  <v-content class="page-content">
    <v-row class="pb-15 page-main-header">
      <v-col cols="12">
        <v-row>
          <v-col cols="10" offset="1" class="text-center">
            <span class="text-center page-main-title text-h6"></span>
            <br />
            <span class="pt-1 text-center page-title text-h4"></span>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row class="mt-n15 center">
      <v-col cols="12" offset="3">
        <v-row>
          <v-col class="pl-5 pr-5 mb-8 d-flex justify-center" cols="6">
            <v-card elevation="8">
              <v-card-title>
                <span class="text-h6"> Editar mi cuenta </span>
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text>
                <v-container>
                  <span class="text-h7 green--text">
                    Información del usuario
                  </span>
                  <v-row>
                    <v-col cols="12" sm="12" lg="6" md="6">
                      <v-text-field label="Nombre(s)" required></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" lg="6" md="6">
                      <v-text-field label="Apellido(s)" required></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" lg="6" md="6">
                      <v-text-field
                        label="Correo electronico"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" lg="6" md="6">
                      <v-text-field
                        label="Documento de identidad"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" lg="6" md="6">
                      <v-text-field label="Teléfono" required></v-text-field>
                    </v-col>
                  </v-row>
                  <span class="text-h7 green--text">
                    Información de seguridad
                  </span>
                  <v-row>
                    <v-col cols="12" sm="12" md="6" lg="6">
                      <v-text-field
                        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                        :rules="[rules.required, rules.min]"
                        :type="showPassword ? 'text' : 'password'"
                        name="input-10-2"
                        label="Contraseña"
                        hint="minimo 8 caracteres"
                        value=""
                        class="input-group--focused"
                        @click:append="showPassword = !showPassword"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="6" lg="6">
                      <v-text-field
                        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                        :rules="[rules.required, rules.min]"
                        :type="showPassword ? 'text' : 'password'"
                        name="input-10-2"
                        label="Confirmar Contraseña"
                        hint="minimo 8 caracteres"
                        value=""
                        class="input-group--focused"
                        @click:append="showPassword = !showPassword"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="light" rounded small @click="dialog = false">
                  CANCELAR
                </v-btn>
                <v-btn color="primary" rounded small> GUARDAR </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-content>
</template>

<script>
export default {
  name: "account.user",
  data() {
    return {
      roles: ["ADMINISTRADOR", "ESTANDAR"],
      showPassword: false,
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => v.length >= 8 || "Min 8 characters",
      },
    };
  },
};
</script>

<style scoped>
.background-card-img {
  border-radius: 50% 50% !important;
  border: white 5px solid;
  height: 100px;
  width: 100px;
}

.page-title {
  color: #43794e;
  border-bottom: 2px solid #dd3333;
  font-size: 38px;
}

.page-main-title {
  color: #43794e;
  font-size: 18px;
  font-weight: bold;
}
.page-main-header {
  background-color: #efefef;
  padding-top: 34px;
}

.page-content {
  padding-top: 0 !important;
}

.rounded-lg {
  border-radius: 18px !important;
}
</style>
